<script setup lang="ts">
// Importing altcha package will introduce a new element <altcha-widget>
if (import.meta.client) await import("altcha");

const { challengeurl = "/api/altcha/challenge", payload = "" } = defineProps<{
  challengeurl?: string;
  payload?: string;
}>();

const emit = defineEmits<{
  "update:payload": [value: string];
}>();

const internalValue = ref(payload);
watch(internalValue, (value) => emit("update:payload", value || ""));

function onStateChange(ev: CustomEvent | Event) {
  if (!("detail" in ev)) return;

  const { payload, state } = ev.detail;
  internalValue.value = state === "verified" && payload ? payload : "";
}

const widget = useTemplateRef<HTMLElement>("widget");
onMounted(() => {
  widget.value?.addEventListener("statechange", onStateChange);
});
onUnmounted(() => {
  widget.value?.removeEventListener("statechange", onStateChange);
});

const { t } = useI18n();
const strings = JSON.stringify({
  ariaLinkLabel: t("altcha.ariaLinkLabel"),
  error: t("altcha.error"),
  expired: t("altcha.expired"),
  footer: t("altcha.footer"),
  label: t("altcha.label"),
  verified: t("altcha.verified"),
  verifying: t("altcha.verifying"),
  waitAlert: t("altcha.waitAlert"),
});
</script>

<template>
  <!-- eslint-disable-next-line vue/component-name-in-template-casing : this is a custom-element and not a vue component -->
  <altcha-widget
    ref="widget"
    style="--altcha-max-width: 100%"
    :challengeurl
    :strings
    auto="onload"
  ></altcha-widget>
</template>
